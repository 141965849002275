import logo from "./headerImg.jpg";
import "./App.css";
import Gigs from "./components/gigs/Gigs";
import { Outlet, useLocation, useNavigate } from "react-router";

function App() {
  const navigate = useNavigate();
  let location = useLocation();

  const showingTips = location.pathname === "/tips";

  return (
    <div className="App">
      {/* <marquee className="reverse">
        This text will scroll from right to left
      </marquee> */}
      <Outlet />
      <div style={{ position: "relative", overflow: "hidden" }}>
        <header className="App-header">
          <img
            src={logo}
            className="App-logo"
            alt="Blind Miles Blues - San Diego (logo)"
          />
        </header>

        <main style={{ margin: "10px 0 50px 0" }}>
          <Gigs />
        </main>

        <footer>
          <div className="d-grid gap-4 col-md-6 col-lg-4 col-xl-3 mx-auto mb-4 p-4">
            <a
              className="btn btn-outline-primary"
              aria-label="original music"
              href="https://blindmiles.bandcamp.com/"
              target="_blank"
            >
              <i class="bi bi-vinyl-fill"></i> Original Music
            </a>
            <a
              className="btn btn-outline-primary position-relative"
              aria-label="merch shop"
              href="https://blindmilesblues.myshopify.com/"
              target="_blank"
            >
              {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
              NEW!
            </span> */}
              <i className="bi bi-shop"></i> Shop Merch
            </a>
            <a
              className="btn btn-outline-primary"
              aria-label="facebook"
              href="https://www.facebook.com/blindmilesblues"
              target="_blank"
            >
              <i className="bi bi-facebook"></i> Facebook
            </a>
            <a
              className="btn btn-outline-primary"
              aria-label="instagram"
              href="https://www.instagram.com/blindmilesblues"
              target="_blank"
            >
              <i className="bi bi-instagram"></i> Instagram
            </a>
            <a
              className="btn btn-outline-primary"
              aria-label="youtube"
              href="https://www.youtube.com/channel/UCbFKVSHWRzzvHyzD4uW6ZcA"
              target="_blank"
            >
              <i className="bi bi-youtube"></i> YouTube
            </a>
            <a
              className="btn btn-outline-primary position-relative"
              aria-label="Linktree"
              href="https://linktr.ee/blindmilesblues"
              target="_blank"
            >
              <i className="bi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m13.736 5.853l4.005-4.117l2.325 2.38l-4.2 4.005h5.908v3.305h-5.937l4.229 4.108l-2.325 2.334l-5.74-5.769l-5.741 5.769l-2.325-2.325l4.229-4.108H2.226V8.121h5.909l-4.2-4.004l2.324-2.381l4.005 4.117V0h3.472zm-3.472 10.306h3.472V24h-3.472z"
                  />
                </svg>
              </i>{" "}
              Linktree
            </a>
          </div>

          <div>
            for booking please contact us at:
            <br />
            <a
              className="secondary-text"
              style={{ fontSize: "larger" }}
              href="&#x6d;&#97;&#105;&#108;&#x74;&#111;&#58;&#098;&#108;&#105;&#110;&#100;&#109;&#105;&#108;&#101;&#115;&#098;&#108;&#117;&#101;&#115;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;?&#115;&#117;&#098;&#106;&#101;&#099;&#116;=&#082;&#069;:&#032;&#066;&#111;&#111;&#107;&#105;&#110;&#103;&#032;&#066;&#108;&#105;&#110;&#100;&#032;&#077;&#105;&#108;&#101;&#115;&#032;&#066;&#108;&#117;&#101;&#115;"
              target="_blank"
            >
              &#098;&#108;&#105;&#110;&#100;&#109;&#105;&#108;&#101;&#115;&#098;&#108;&#117;&#101;&#115;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
            </a>
          </div>
        </footer>
      </div>

      {!showingTips && (
        <div className="tips-container" onClick={() => navigate("/tips")}>
          <div className="tips-pointer bounce">
            <div className="tips-pointer-inner">
              <i class="bi bi-hand-index-thumb" style={{ zIndex: 1 }}></i>
              <i
                class="bi bi-hand-index-thumb-fill"
                style={{ color: "#efefef" }}
              ></i>
            </div>
          </div>

          <span className="secondary-text">Leave us</span>
          <div className="h4 ">Tips</div>
          {/* <h4 className="">$$$</h4> */}
          <span className="secondary-text">
            . . .
            <br />
            for the Burrito Fund
          </span>
        </div>
      )}
    </div>
  );
}

export default App;
