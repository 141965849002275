import React from "react";
import useGigs from "../../hooks/useGigs";
import moment from "moment-timezone";
import scratch from "../../scratch.png";
import { times } from "lodash";
import { Container, Placeholder, Row } from "react-bootstrap";
import classNames from "classnames";

const Gigs = () => {
  const { gigs, loading } = useGigs();

  // if (loading) {
  //   return <h2>Loading...</h2>;
  // }

  if (!loading && !gigs.length) {
    return <h2>No Upcoming Gigs</h2>;
  }

  return (
    <div style={{ width: "fit-content", minWidth: 400, margin: "0 auto" }}>
      <h3>
        <span>Upcoming Gigs</span>
      </h3>

      {loading &&
        times(3, (i) => (
          <Placeholder
            as="div"
            animation="glow"
            className="text-start d-flex p-2"
          >
            <div class="w-25">
              <Placeholder as="div" style={{ width: 84, height: 110 }} />
            </div>
            <div class="w-75">
              <Container>
                <Row as="p">
                  <Placeholder as="div" style={{ minHeight: 2 }} />
                </Row>
                <Row as="p">
                  <Placeholder as="col" xs={12} />
                </Row>
                <Row as="p">
                  <Placeholder as="col" xs={4} />
                </Row>
                <Row as="p">
                  <Placeholder as="col" xs={3} />
                </Row>
                <Row>
                  <Placeholder as="div" style={{ minHeight: 2 }} />
                </Row>
              </Container>
            </div>
          </Placeholder>
        ))}

      {gigs.map((gig, i) => {
        const todaysDate = moment(new Date())
          .tz("America/Los_Angeles")
          .format("M/D/yyyy");

        const gigDate = moment(gig.start.dateTime)
          .tz("America/Los_Angeles")
          .format("M/D/yyyy");

        const isToday = todaysDate === gigDate;

        const iconClasses = classNames({
          "gig-info": true,
          h5: true,
          first: i === 0,
          // "border-blink": i === 0,
        });

        return (
          <div key={gig.id} className="gig-row">
            <div className="gig-date">
              <div
                className="gig-month"
                style={{
                  backgroundPosition: `${i * 20}px ${i + 2}px`,
                }}
              >
                {moment(gig.start.dateTime)
                  .tz("America/Los_Angeles")
                  .format("MMM")}
              </div>
              <div className="gig-day">
                {moment(gig.start.dateTime)
                  .tz("America/Los_Angeles")
                  .format("D")}
              </div>
              <div className="small gig-year">
                {moment(gig.start.dateTime)
                  .tz("America/Los_Angeles")
                  .format("yyyy")}
              </div>
            </div>

            <div className={iconClasses}>
              <a
                href={`http://maps.google.com/?q=${gig.summary} - ${gig.location}`}
                target="_blank"
              >
                <div style={{ fontSize: "larger" }}>{gig.summary}</div>
                <div style={{ fontSize: "medium", paddingTop: 5 }}>
                  <i className="bi bi-geo-alt-fill"></i>
                  <span style={{}}>{gig.location}</span>
                </div>
              </a>
              <div
                className="small secondary-text"
                style={{
                  paddingTop: "10px",
                  fontWeight: "bold",
                }}
              >
                {moment(gig.start.dateTime)
                  .tz("America/Los_Angeles")
                  .format("h:mm")}
                -
                {moment(gig.end.dateTime)
                  .tz("America/Los_Angeles")
                  .format("h:mma")}
              </div>

              {isToday && <div className="today">today</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Gigs;
