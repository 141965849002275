import React, { useState } from "react";
import useGigs from "../../hooks/useGigs";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router";

const Tips = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/");
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <Modal.Header className="reverse" style={{ justifyContent: "center" }}>
        <Modal.Title className="fs-1">Tip the band</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {/* <h1>TIP The Band</h1> */}
        <div className="secondary-text fs-4">
          If you are enjoying the music, <br />
          please consider leaving a tip.
          <br />
          <span className="secondary-text fs-2">Thank you!</span>
        </div>
        <div style={{ position: "relative", height: 240 }}>
          <i class="bi bi-cash bounce90 tips-cash"></i>
          <i class="bi bi-piggy-bank-fill tips-bank"></i>
        </div>
        <div>
          <p>
            <a
              className="btn btn-outline-primary"
              aria-label="Venmo"
              href="https://www.venmo.com/u/blindmilesblues"
              target="_blank"
            >
              <i class="bi bi-vimeo"></i> Venmo
            </a>
            {"  "}
            <a
              className="btn btn-outline-primary"
              aria-label="Paypal"
              href="https://www.paypal.biz/blindmilesblues"
              target="_blank"
            >
              <i class="bi bi-paypal"></i> Paypal
            </a>
          </p>

          <Link
            as={Button}
            to="/"
            className="secondary-text fs-4"
            variant="link"
          >
            No Thanks
          </Link>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default Tips;
